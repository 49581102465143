import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardInfo from '../../Layout/CardInfo'
import CardBox from '../../Layout/CardBox'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { AlertApp } from '../../Global/AlertApp'
import ActionButtonsForRows from '../../Layout/ActionButtonsForRows'
import DataTableApp from '../../Layout/DataTableApp'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionsSBO, ActivitysStatesSBOColors, ActivitysStatesSBOSelect, ListOfDocStatesSBO, StateActivitysSBO } from '../../../Types'
import { Badge, ButtonGroup, Col, Row, UncontrolledTooltip } from 'reactstrap'
import CalendarApp from '../../Layout/CalendarApp'
import { AuthContext } from '../../../AuthContext'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
// apollo
import { CANCEL_ACTIVITY, GET_ALL_ACTIVITIES, GET_ALL_ACTIVITIES_TYPES, CREATE_ACTIVITY_PROSPECTING, ATTACHED_CONTACTS_ACTIVITY } from '../../../graphql/Catalogs/Activities'
import { GET_ALL_SALES_PERSONS } from '../../../graphql/QueryManager'
import InputApp from '../../Layout/InputApp'
import RegisterProspectingModal from './Activities/RegisterProspectingModal'
import AddProspectContact from './Activities/AddProspectContact'
import { CREATE_CONTACT_PERSON } from '../../../graphql/Catalogs/Opportunities'

export const Activities = () => {
    const { user: userContext } = useContext(AuthContext)
    const navigate = useNavigate()
    const [dataList, setDataList] = useState([])
    const [dataEvent, setDataEvent] = useState([])
    const [listOfSalesPerson, setListOfSalesPerson] = useState([])
    const [listOfContacts, setListOfContacts] = useState([])
    const [activitiesTypes, setActivitiesTypes] = useState([])
    const [queryFilter, setQueryFilter] = useState([])
    const [filterList, setFilterList] = useState([])
    const [filterEventList, setFilterEventList] = useState([])
    const [activityType, setActivitiesType] = useState('')
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [contactsIsOpen, setContactsIsOpen] = useState(false)
    const [activity, setActivity] = useState(null)
    const { control, getValues, setValue } = useForm()

    const { loading, data, error, refetch } = useQuery(GET_ALL_ACTIVITIES, {
        fetchPolicy: 'cache-and-network'
    })
    const { loading: loadingTypes, error: errorTypes, data: dataTypes } = useQuery(GET_ALL_ACTIVITIES_TYPES)

    const { loading: loadingSalesPerson, error: errorSalesPerson, data: dataSalesPerson } = useQuery(GET_ALL_SALES_PERSONS, {
        fetchPolicy: 'cache-and-network'
    })

    const [onCancel] = useMutation(CANCEL_ACTIVITY)
    const [onCreatePorspecting] = useMutation(CREATE_ACTIVITY_PROSPECTING)
    const [createContactPerson] = useMutation(CREATE_CONTACT_PERSON)
    const [getContacts, { loading: loadingContacs, error: errorContacts, data: dataContacts }] = useLazyQuery(ATTACHED_CONTACTS_ACTIVITY, {
        fetchPolicy: 'cache-and-network'
    })

    const toggleContacts = () => setContactsIsOpen(!contactsIsOpen)

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ type: 'toastError', message: error })
            }
            let list = []
            let events = []

            if (data) {
                data.allActivities.map((element) => {

                    let cloneElement = { ...element }
                    cloneElement.id = element.activityCode

                    let buttons = <ActionButtonsForRows
                        baseUrl={'crm/activities'}
                        element={{ id: element.activityCode }}
                        editButton={(element.closed == "tNO" && element.cardCode !== "")}
                        showButton
                        deleteButton={element.closed == "tNO"}
                        deleteAction={() => submitCancelActivity(element)}
                        modalButton={element.cardCode === ""}
                        modalButtonAction={async () => {
                            setActivity(element)
                            await getContacts({ variables: { id: element.activityCode } })

                        }}
                    />

                    cloneElement.actions = buttons
                    // if (element.closed === "tNO")

                    events.push({
                        id: element.activityCode,
                        title: `${element.cardCode !== "" ? element.cardName : element.details}`,
                        description: element.notes,
                        start: element.start,
                        end: moment(element.endDuedate).format('YYYY-MM-DD') + ' ' + moment(element.endTime).format('HH:mm'),
                        endTime: moment(element.CloseDate).format('YYYY-MM-DD'),
                        allDay: element.allDay,
                        url: `/crm/activities/edit/${element.activityCode}`,
                        color: element.closed == "tNO" ? '#6c757d' : '#5cb85c',
                        salesEmployee: element.salesEmployee,
                        groupId: element.salesEmployee,
                        display: element.cardCode !== "" ? 'list-item' : 'block',
                        overlap: false,
                    })
                    return list.push(cloneElement)
                })
            }
            setDataList(list)
            setDataEvent(events)
            setFilterList(list)
            setFilterEventList(events)
            setValue("status", "")
        }
    }, [loading, data, error])

    useEffect(() => {
        if (!loadingTypes) {
            if (errorTypes) {
                AlertApp({ type: 'errorToast', message: errorTypes })
            } else {
                let list = []

                dataTypes.allActivityTypes.map((element) => {

                    return list.push({
                        code: element.code,
                        name: element.name,
                    })
                })
                setActivitiesTypes(list)
            }
        }
    }, [loadingTypes, errorTypes, dataTypes])

    useEffect(() => {
        if (!loadingSalesPerson) {
            if (errorSalesPerson) {
                AlertApp({ type: 'errorToast', message: errorSalesPerson })
            }
            let list = []
            if (dataSalesPerson) {

                dataSalesPerson.allSalesPersons.map((element) => {
                    return list.push({
                        value: element.slpCode,
                        label: element.slpName,
                        disabled: element.active === "N",
                    })
                })

            }
            setListOfSalesPerson(list)
        }
    }, [loadingSalesPerson, errorSalesPerson, dataSalesPerson])

    useEffect(() => {
        let cloneElement = { ...queryFilter }
        if (activityType != "")
            cloneElement.activityType = activityType

        setFilterList(dataList.filter(item => {
            for (let key in cloneElement) {
                if (item[key] === undefined || item[key] != cloneElement[key])
                    return false;
            }
            return true;
        }))
        setFilterEventList(dataEvent.filter(item => {
            for (let key in cloneElement) {
                if (item[key] === undefined || item[key] != cloneElement[key])
                    return false;
            }
            return true;
        }))
    }, [queryFilter, activityType])

    useEffect(() => {
        if (activity) {
            if (!loadingContacs) {
                if (errorContacts) {
                    AlertApp({ type: 'errorToast', message: errorContacts })
                } else {
                    setListOfContacts(dataContacts.allContactPersonByActivity)
                    setModalIsOpen(!modalIsOpen)
                }
            }
        }
    }, [loadingContacs, errorContacts, dataContacts, activity])

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            width: '70px',
        },
        {
            name: 'Actividad',
            selector: row => row.activityProperty, // `${ActionsSBO.find((element) => element.value == row.activityProperty).label}`,            
            width: '120px',
        },
        {
            name: 'Empleado',
            selector: row => `${listOfSalesPerson.find((s) => s.value == row.salesEmployee)?.label}`,
            omit: userContext.Rol === 3,
            width: '120px',
        },
        {
            name: 'Status',
            selector: row => <Badge color={ActivitysStatesSBOColors[row.status]}> {`${StateActivitysSBO[row.status]}`}</Badge>,
            width: '87px',
        },
        {
            name: 'Fecha de apertura',
            selector: row => moment(new Date(row.activityDate)).format('YYYY-MM-DD'),
            width: '130px',
        },
        {
            name: 'Socio de negocio',
            selector: row => `${row.cardCode} - ${row.cardName}`,

        },
        {
            name: 'Contacto',
            selector: row => <span id={`contactIconRow_${row.activityCode ? row.activityCode : row.id}`}><FontAwesomeIcon icon={"user"} /> {row.contactPerson ?
                <>
                    {`${row.contactPerson.name}`}

                    <UncontrolledTooltip target={`contactIconRow_${row.activityCode ? row.activityCode : row.id}`}>
                        {`Nombre: ${row.contactPerson.firstName} ${row.contactPerson.lastName}\n
                      Correo: ${row.contactPerson.e_MailL}\n
                      Tel: ${row.contactPerson.tel1}`}
                    </UncontrolledTooltip>
                </>
                : ""} </span>,
            sortable: false,
        },
        {
            name: 'Objetivo',
            selector: row => `${row.details}`,
            sortable: false,
        },
        {
            name: '',
            selector: row => row.actions,
            sortable: false,
        },
    ]
    const conditionalRowStyles = [
        // {
        //     when: row => row.status === -2,
        //     style: {
        //         backgroundColor: '#28a745',
        //     },
        // }
    ]


    const handlerFilter = (event) => {
        const slpCode = getValues('slpCode')
        const status = getValues('status')

        let query = {}



        if (slpCode != -1 && slpCode != '')
            query.salesEmployee = slpCode



        if (status != "")
            query.status = status

        setQueryFilter(query)
    }

    const submitCancelActivity = async (element) => {

        var htmlString = `
        Se va <b className='text-danger'>Cancelar</b> la <b>${element.activityProperty}</b> con <p>${element.contactPerson.firstName} ${element.contactPerson.lastName}</p>
        <b>${element.cardCode} - ${element.cardName}</b>
      `
        const result = await AlertApp({
            html: htmlString,
            type: 'question',
            okButtonText: 'Confirmar'
        })

        if (result.isConfirmed) {
            if (result.value != "") {
                onCancel({
                    variables: {
                        input: {
                            docEntry: element.activityCode,
                            id: element.assignedTask.id,
                            reason: result.value
                        }
                    },
                    refetchQueries: refetch
                })
                AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })

            } else {
                AlertApp({ type: 'error', message: 'Es necesario proporcionar el motivo de la cancelación' })
            }
        }
    }

    const addButtonsTools = () => {
        return <>
            <ButtonGroup>
                <button type={"button"} className="btn btn-primary" onClick={() => {
                    refetch()
                }}>
                    <FontAwesomeIcon icon={"rotate"} />{" Actualizar"}
                </button>
                <button type={"button"} className="btn btn-primary" onClick={() => {
                    setQueryFilter({})
                    setActivitiesType("")
                    setValue('slpCode', "-1")
                    setValue('status', "")
                }}>
                    <FontAwesomeIcon icon={"filter-circle-xmark"} />{" Limpiar filtros"}
                </button>
                {userContext.Rol === 1 && (
                    <a className="btn btn-secondary" onClick={() => navigate('add')}>
                        <FontAwesomeIcon icon={"calendar-plus"} />{" Asignar una actividad"}
                    </a>
                )}
                {(userContext.Rol === 3 || userContext.Rol === 4) && (
                    <a className="btn btn-secondary" onClick={() => navigate('add')}>
                        <FontAwesomeIcon icon={"calendar-plus"} />{" Agendar visita"}
                    </a>
                )}
                {(userContext.Rol === 1 || userContext.Rol === 3 || userContext.Rol === 4) && (
                    <button className="btn btn-secondary" onClick={() => {
                        setActivity(null)
                        setModalIsOpen(!modalIsOpen)
                    }}>
                        <FontAwesomeIcon icon={"calendar-plus"} />{" Registrar prospección"}
                    </button>
                )}
            </ButtonGroup>
        </>
    }
    const ToogleDetailReceipt = ({ data }) => {
        return <>
            {data.cardCode !== "" && (
                <Row>
                    <Col md={9}>
                        <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                            <div className="card bg-light d-flex flex-fill">
                                <div className="card-body pt-0">
                                    <div className="row">
                                        <div className="col-10">
                                            <p className="text-muted text-sm"><b>Contacto: </b> {`${data.contactPerson.name ?? 'Sin Comentario'}`} </p>
                                            <ul className="ml-4 mb-0 fa-ul text-muted">
                                                <li className="small"><span className="fa-li"><i className="fas fa-lg fa-at"></i></span> Correo: {`${data.contactPerson.e_MailL}`}</li>
                                                <li className="small"><span className="fa-li"><i className="fas fa-lg fa-phone"></i></span> Telefono #: {`${data.contactPerson.tel1}`}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Col>
                </Row>
            )}
            {data.cardCode === "" && (
                <>
                    {data.contacts.map((element, index) => {
                        return <Row key={index}>
                            <Col md={9}>
                                <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                                    <div className="card bg-light d-flex flex-fill">
                                        <div className="card-body pt-0">
                                            <div className="row">
                                                <div className="col-10">
                                                    <p className="text-muted text-sm"><b>Contacto: </b> {`${element.firstName} ${element.lastName}`} </p>
                                                    <ul className="ml-4 mb-0 fa-ul text-muted">
                                                        <li className="small"><span className="fa-li"><i className="fas fa-lg fa-at"></i></span> Correo: {`${element.e_MailL}`}</li>
                                                        <li className="small"><span className="fa-li"><i className="fas fa-lg fa-phone"></i></span> Telefono #: {`${element.tel1}`}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    })}
                </>
            )}
        </>
    }
    return (
        <DashboardLayout>
            <Row>
                {activitiesTypes.map((element) => {

                    const total = filterList.filter((row) => row.activityType == element.code).length
                    if (total > 0)
                        return <Col lg={2} key={element.code}><CardInfo title={element.name} value={total} type={`${element.code >= 9 ? 'primary' : 'info'}`} actionClick={() => {

                            setActivitiesType(element.code)
                            handlerFilter()
                        }} /></Col>
                })}
            </Row>
            <CardBox
                title={'Actividades'}
                loading={loading || loadingSalesPerson}
                actionTools={addButtonsTools()}
                content={<>

                    <Row>
                        {userContext.Rol === 1 && (<Col>
                            <InputApp inputType={"select"} name={"slpCode"} label={"Vendedor"} control={control} listOfOptions={listOfSalesPerson} changeAction={(e) => handlerFilter(e)} />
                        </Col>)}
                        {(userContext.Rol === 4) && (<Col>
                            <InputApp inputType={"select"} name={"slpCode"} label={"Vendedor"} control={control} listOfOptions={listOfSalesPerson} changeAction={(e) => handlerFilter(e)} />
                        </Col>)}
                        <Col>
                            <InputApp inputType={"select"} name={"status"} label={"Status"} control={control} listOfOptions={ActivitysStatesSBOSelect} changeAction={(e) => handlerFilter(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DataTableApp
                                data={filterList}
                                columns={columns}
                                dense
                                conditionalRowStyles={conditionalRowStyles}
                                filter
                                expandableRows
                                expandableRowsComponent={ToogleDetailReceipt}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CalendarApp
                                data={filterEventList}
                                dateClick={function (info) {

                                    Swal.fire({
                                        title: `Crear una nueva activdad en la fecha ${info.dateStr}`,
                                        showDenyButton: true,

                                        confirmButtonText: "Crear",
                                        denyButtonText: `Cancelar`
                                    }).then((result) => {
                                        /* Read more about isConfirmed, isDenied below */
                                        if (result.isConfirmed) {
                                            navigate(`/crm/activities/calendar/${info.dateStr}/cn_Meeting`)
                                        }
                                    })
                                }}
                            />
                        </Col>
                    </Row>

                </>}
            />

            <RegisterProspectingModal _setDialog={setModalIsOpen} _dialog={modalIsOpen} onCreate={onCreatePorspecting} activity={activity} activitiesTypes={activitiesTypes} contacts={listOfContacts} toggleContacts={toggleContacts} />
            <AddProspectContact _setDialog={setContactsIsOpen} _dialog={contactsIsOpen} activity={activity} onCreate={createContactPerson} />
        </DashboardLayout>
    )
}
